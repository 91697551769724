<template>
  <b-modal
    id="procedure-create-modal"
    title="Procedure Create Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Procedure Create</h2>
    </template>
    <validation-observer ref="procedureCreateFormValidation">
      <b-form @submit.prevent="validationForm">
        <b-form-group label-for="name">
          <template #label>
            Procedure Name <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="name"
            v-model="name"
            v-restrict="regex"
            placeholder="Procedure Name"
            required
            key="name"
            @keydown="checkMaxLength($event, key, 400)"
          />
        </b-form-group>

        <b-form-group label-for="description">
          <template #label> Description </template>
          <b-form-textarea
            id="description"
            v-model="description"
            placeholder="Description"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label-for="amount">
          <template #label>
            Amount <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="amount"
            v-model="amount"
            placeholder="Amount"
            required
            type="number"
            min="1"
            step="1"
          />
          <b-form-invalid-feedback v-if="!amountState">
            Please enter a valid positive integer.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label-for="duration">
          <template #label>
            Duration <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="duration"
            v-model="duration"
            type="number"
            min="1"
            step="1"
            placeholder="Duration"
            required
          />
          <b-form-invalid-feedback v-if="!amountState">
            Please enter a valid positive integer.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label-for="hospital">
          <template #label>
            Hospital <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Hospital"
            rules="required"
          >
            <v-select
              label="hospitalLabel"
              v-model="hospital"
              placeholder="Select Hospital"
              :options="formattedHospitals"
              autoscroll
              :clearable="true"
              required
            ></v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="department">
          <template #label>
            Department <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Department"
            rules="required"
          >
            <v-select
              label="name"
              v-model="department"
              placeholder="Select Department"
              :options="departments"
              autoscroll
              :clearable="true"
              required
            ></v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="doctor">
          <template #label> Doctor </template>
          <v-select
            label="name"
            v-model="doctor"
            placeholder="Select Doctor"
            :options="doctors"
            autoscroll
            :clearable="true"
          ></v-select>
        </b-form-group>

        <b-form-group label-for="status">
          <template #label>
            Status <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="status"
            v-model="status"
            :options="options"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group class="mt-2 text-right">
          <b-button type="submit" variant="primary" pill class="mr-1">
            Save
          </b-button>
          <b-button
            type="reset"
            variant="outline-secondary"
            @click="reset"
            pill
          >
            Reset
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  mixins: [util],
  data() {
    return {
      required,
      name: "",
      description: "",
      amount: "",
      duration: "",
      hospital: null,
      hospitals: [],
      department: null,
      departments: [],
      doctors: [],
      doctor: null,
      status: "",
      options: [
        { value: "", text: "-- Select --" },
        { value: true, text: "Enable" },
        { value: false, text: "Disable" },
      ],
      regex: /^[a-zA-Z ]*$/,
    };
  },
  methods: {
    ...mapActions({
      createProcedure: "appData/createProcedure",
      getHospitalsPublic: "appData/getHospitalsPublic",
      getDepartmentsPublic: "appData/getDepartmentsPublicNav",
      getDoctorsPublic: "appData/getDoctorsPublicNav",
    }),
    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    async validationForm() {
      const success = await this.$refs.procedureCreateFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },

    async submit() {
      try {
        console.log(this.doctor);
        const res = await this.createProcedure({
          name: this.name,
          description: this.description,
          amount: this.amount,
          duration: this.duration,
          hospital: this.hospital.id,
          department: this.department.id,
          doctor: this.doctor ? this.doctor.id : null,
          status: this.status,
          created_by: this.getLoggedInUser.id,
          updated_by: this.getLoggedInUser.id,
        });
        console.log(res);
        if (res.status === 200) {
          this.$swal({
            title: "Procedure created successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("procedure-create-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.name = "";
      this.description = "";
      this.amount = "";
      this.duration = "";
      this.hospital = null;
      this.department = null;
      this.doctor = null;
      this.status = "";
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
    amountState() {
      return (
        this.amount !== "" &&
        Number.isInteger(Number(this.amount)) &&
        Number(this.amount) > 0
      );
    },

    formattedHospitals() {
      return this.hospitals.map((hospital) => {
        return {
          ...hospital,
          hospitalLabel: `${hospital.name} - ${hospital.location}`,
        };
      });
    },
  },
  async mounted() {
    const res_hospitals = await this.getHospitalsPublic();
    this.hospitals = res_hospitals.data;

    const res_departments = await this.getDepartmentsPublic();
    this.departments = res_departments.data;

    const res_doctors = await this.getDoctorsPublic();
    this.doctors = res_doctors.data;
  },
};
</script>

<style></style>
