<template>
  <b-modal
    id="procedure-edit-modal"
    title="Procedure Edit Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Procedure Edit</h2>
    </template>
    <validation-observer ref="procedureEditFormValidation">
      <b-form @submit.prevent="validationForm">
        <b-form-group label-for="name">
          <template #label>
            Procedure Name <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="name"
            v-model="name"
            v-restrict="regex"
            required
            placeholder="Procedure Name"
            @keydown="checkMaxLength($event, 'name', 400)"
          />
        </b-form-group>

        <b-form-group label-for="description">
          <template #label> Description </template>
          <b-form-textarea
            id="description"
            v-model="description"
            placeholder="Description"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label-for="amount">
          <template #label>
            Amount <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="amount"
            v-model="amount"
            required
            type="number"
            min="1"
            step="1"
            placeholder="Amount"
          />
          <b-form-invalid-feedback v-if="!amountState">
            Please enter a valid positive integer.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label-for="duration">
          <template #label>
            Duration <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="duration"
            v-model="duration"
            required
            type="number"
            min="1"
            step="1"
            placeholder="Duration"
          />
          <b-form-invalid-feedback v-if="!amountState">
            Please enter a valid positive integer.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label-for="hospital">
          <template #label>
            Hospital <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Hospital"
            rules="required"
          >
            <v-select
              label="name"
              v-model="hospital"
              placeholder="Select Hospital"
              :options="hospitals"
              :reduce="(option) => option.id"
              autoscroll
              :clearable="true"
            ></v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="department">
          <template #label>
            Department <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Department"
            rules="required"
          >
            <v-select
              label="name"
              v-model="department"
              placeholder="Select Department"
              :options="departments"
              :reduce="(option) => option.id"
              autoscroll
              :clearable="true"
            ></v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="doctor">
          <template #label> Doctor </template>
          <v-select
            label="name"
            v-model="doctor"
            placeholder="Select Doctor"
            :options="doctors"
            :reduce="(option) => option.id"
            autoscroll
            :clearable="true"
          ></v-select>
        </b-form-group>

        <b-form-group label-for="status">
          <template #label>
            Status <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="status"
            v-model="status"
            :options="options"
          ></b-form-select>
        </b-form-group>

        <b-form-group class="mt-2 text-right">
          <b-button type="submit" variant="info" pill class="mr-1">
            Update
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  mixins: [util],
  props: {
    procedure: Object,
  },
  data() {
    return {
      required,
      name: "",
      description: "",
      amount: "",
      duration: "",
      hospital: null,
      hospitals: [],
      department: null,
      departments: [],
      doctors: [],
      doctor: null,
      status: "",
      options: [
        { value: "", text: "-- Select --" },
        { value: true, text: "Enable" },
        { value: false, text: "Disable" },
      ],
      regex: /^[a-zA-Z ]*$/,
    };
  },
  async mounted() {
    const res_hospitals = await this.getHospitalsPublic();
    this.hospitals = res_hospitals.data;

    const res_departments = await this.getDepartmentsPublic();
    this.departments = res_departments.data;

    const res_doctors = await this.getDoctorsPublic();
    this.doctors = res_doctors.data;

    if (this.procedure) {
      this.name = this.procedure.name;
      this.description = this.procedure.description;
      this.amount = this.procedure.amount;
      this.duration = this.procedure.duration;
      this.hospital = this.procedure.hospital;
      this.department = this.procedure.department;
      this.doctor = this.procedure.doctor;
      this.status = this.procedure.status;
    }
  },
  methods: {
    ...mapActions({
      updateProcedure: "appData/updateProcedure",
      getHospitalsPublic: "appData/getHospitalsPublic",
      getDepartmentsPublic: "appData/getDepartmentsPublicNav",
      getDoctorsPublic: "appData/getDoctorsPublicNav",
    }),
    async validationForm() {
      const success = await this.$refs.procedureEditFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    async validationForm() {
      const success = await this.$refs.procedureEditFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async submit() {
      try {
        const res = await this.updateProcedure({
          payload: {
            name: this.name,
            description: this.description,
            amount: this.amount,
            duration: this.duration,
            hospital: this.hospital,
            department: this.department,
            doctor: this.doctor,
            status: this.status,
            updated_by: this.getLoggedInUser.id,
          },
          pk: this.procedure.id,
        });
        if (res.status === 200) {
          this.$swal({
            title: "Procedure updated successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("procedure-edit-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.name = "";
      this.description = "";
      this.amount = "";
      this.duration = "";
      this.hospital = null;
      this.department = null;
      this.doctor = null;
      this.status = "";
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
    amountState() {
      return (
        this.amount !== "" &&
        Number.isInteger(Number(this.amount)) &&
        Number(this.amount) > 0
      );
    },
  },
};
</script>

<style></style>
